<template>
    <v-container fluid id="clients_page">

        <v-row dense>
            <v-col cols="12" lg="12">
                <v-expansion-panels v-model="search_panel" :disabled="loading">
                    <v-expansion-panel>
                        <v-expansion-panel-header>{{$t('general.search')}}</v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-row dense>
                                <v-col cols="12" lg="12" md="4" sm="6">
                                    <v-text-field name="search_client" type="text" class="custom_field"
                                                  v-model="search_client" :label="$t('clients.client')" outlined
                                                  clearable>
                                    </v-text-field>
                                </v-col>
                                <v-col cols="12" align="right">
                                    <v-btn dark :disabled="loading" @click="clearSearchForm">
                                        {{$t('general.clear')}}
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
            </v-col>
        </v-row>
        <v-row dense>
            <v-col cols="12" lg="12">
                <!--Datatable Toolbar-->
                <v-toolbar flat color="white" elevation="1" class="custom_datatable_toolbar" height="56">
                    <v-toolbar-title>{{$t('clients.list')}}</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn v-if="canCreateItem" color="green" dark class="custom_button mobile_button"
                           @click="openItemDialog('create', 0)" :title="$t('clients.create')">
                        <v-icon small size="20">add</v-icon>
                    </v-btn>
                </v-toolbar>

                <!--Datatable-->
                <ResponsiveDataTable :headers="headers" :items="items" :totalItems="total_items" :loading="loading"
                                     :mobileBreakpoint="960" :itemsPerPage="500"
                                     :itemsPerPageOptions="[10, 50, 100, 200, 500]" @options-changed="optionsChanged">
                    <!--Buttons-->
                    <template v-slot:buttons="{item}">
                        <div class="actions">
                            <v-icon v-if="canViewItem" color="black" :title="$t('clients.view')"
                                    @click="openItemDialog('view', item.item.id)">view_list
                            </v-icon>
                            <v-icon v-if="$Helper.methods.canUserDoAction(canUpdateItem, isSuperAdmin, item.item)"
                                    color="primary" :title="$t('general.update')"
                                    @click="openItemDialog('update', item.item.id)">
                                edit
                            </v-icon>
                            <!--<v-icon v-if="$Helper.methods.canUserDoAction(canDeleteItem, isSuperAdmin, item.item)"-->
                            <!--color="red" :title="$t('general.delete')"-->
                            <!--@click="showDeleteItemDialog(item.item)">delete-->
                            <!--</v-icon>-->
                        </div>
                    </template>
                </ResponsiveDataTable>
            </v-col>
        </v-row>

        <!--Item Dialog-->
        <ManageClientDialog :show="show_item_dialog" :item_header="item_dialog_header"
                            :is_loading="item_dialog_loading" :item_action="item_action" :item_id="item_id"
                            @close-dialog="closeItemDialog" @submit-dialog="submitItemDialog">
        </ManageClientDialog>

        <!--Delete item dialog-->
        <StatusDialog :show="show_delete_item_dialog" :header="$t('clients.delete')" color="error"
                      :text="$t('clients.delete_confirmation')" :is_loading="delete_item_dialog_loading"
                      @confirm="deleteItem" @close="closeDeleteItemDialog">
        </StatusDialog>

    </v-container>
</template>

<script>
    import ResponsiveDataTable from '../../components/ResponsiveDataTable.vue'
    import StatusDialog from '../../components/StatusDialog.vue'
    import Dialog from '../../components/Dialog.vue'
    import ManageClientDialog from '../../components/ManageClientDialog.vue'
    import _ from 'lodash'

    export default {
        components: {
            ResponsiveDataTable, StatusDialog, Dialog, ManageClientDialog
        },
        data: self => ({
            url: 'clients',

            // actions permissions
            canViewItem: false,
            canCreateItem: false,
            canUpdateItem: false,
            canDeleteItem: false,

            // search
            search_panel: 0,
            search_client: null,
            search: {},
            search_filters: {},

            // datatable
            headers: [],
            total_items: null,
            items: [],
            loading: false,
            options: {
                page: null,
                itemsPerPage: null,
                sortBy: [],
                sortDesc: [],
                groupBy: [],
                groupDesc: [],
                multiSort: false,
                mustSort: false
            },

            item_id: null,

            // manage item
            show_item_dialog: false,
            item_dialog_header: null,
            item_dialog_loading: false,
            item_action: null,

            // delete item
            show_delete_item_dialog: false,
            delete_item_dialog_loading: false
        }),
        computed: {
            locale() {
                return this.$i18n.locale;
            },
            isSuperAdmin() {
                return this.$store.state.user_details.roles.map(obj => obj.name).indexOf('superadmin') > -1;
            }
        },
        created() {
            this.headers = this.getHeaders();
        },
        async mounted() {
            this.canCreateItem = await this.$store.dispatch('hasPermission', 'create_client');
            this.canViewItem = await this.$store.dispatch('hasPermission', 'view_client');
            this.canUpdateItem = await this.$store.dispatch('hasPermission', 'update_client');
            this.canDeleteItem = await this.$store.dispatch('hasPermission', 'delete_client');
            this.setDefaultSearchCriteria();
        },
        watch: {
            locale(val) {
                this.headers = this.getHeaders();
            },
            search: {
                handler(val) {
                    let filters = this.$Helper.methods.createSearchFilters(Object.assign({}, val));

                    // if filters did change get data
                    if (JSON.stringify(this.filters) !== JSON.stringify(filters)) {
                        this.search_filters = filters;

                        setTimeout(() => {
                            this.getData();
                        }, 100);
                    }
                },
                deep: true
            },
            // change on client search field
            search_client: _.debounce(async function (val) {
                if (val) {
                    if (val.length >= 3) {
                        this.search_filters.client = val;
                        this.getData();
                    }
                } else {
                    delete this.search_filters.client;
                    this.getData();
                }
            }, 500),
        },
        methods: {
            // return headers array
            getHeaders() {
                return [
                    {text: `${this.$t('general.full_name')}`, value: 'client_name', sortable: true},
                    {text: `${this.$t('general.phone_number')}`, value: 'phone_number', sortable: true},
                    {text: `${this.$t('general.email')}`, value: 'email', sortable: true},
                    {text: `${this.$t('datatable.actions')}`, value: 'actions', align: 'right', sortable: false}
                ]
            },

            // search
            // set default values for search form
            setDefaultSearchCriteria() {
            },
            // clear search form
            clearSearchForm() {
                this.setDefaultSearchCriteria();
            },

            // datatable
            // apply changed options on datatable
            async optionsChanged(options) {
                let options_changed = false;
                this.loading = true;

                // check if options changed
                for (let prop in this.options) {
                    if (this.options[prop] !== options[prop]) {
                        options_changed = true;
                        break;
                    }
                }

                if (options_changed) {
                    this.options = Object.assign({}, options);
                    await this.getData();
                }
            },
            // get data from API
            async getData() {
                this.loading = true;

                let response = await this.$store.dispatch('apiRequest', {
                    method: 'get', url: this.url, params: {
                        options: this.options, search_filters: this.search_filters
                    }
                });


                this.loading = false;

                // successful response
                if (!response.response) {
                    this.total_items = response.data.total_items;
                    this.items = response.data.items;
                }
            },

            // Manage Item
            openItemDialog(action, id) {
                this.item_dialog_header = (action === 'create') ? this.$t('clients.create')
                    : (action === 'update') ? this.$t('clients.update') : this.$t('clients.view');
                this.item_id = id;
                this.item_action = action;
                this.show_item_dialog = true;
            },
            // clear item dialog related data
            clearItemDialog() {
                this.item_dialog_header = null;
                this.item_id = null;
                this.item_action = null;
            },
            // close item dialog
            closeItemDialog() {
                this.show_item_dialog = false;
                this.clearItemDialog();
            },

            // submit item dialog
            submitItemDialog() {
                this.show_item_dialog = false;
                this.clearItemDialog();
                this.getData();
            },

            // Delete item
            // show delete item dialog
            showDeleteItemDialog(item) {
                this.show_delete_item_dialog = true;
                this.item_id = item.id;
            },
            // close delete item dialog
            closeDeleteItemDialog() {
                this.show_delete_item_dialog = false;
                this.item_id = null;
            },
            // delete item
            async deleteItem() {
                this.delete_item_dialog_loading = true;

                let response = await this.$store.dispatch('apiRequest', {
                    method: 'delete', url: `${this.url}/${this.item_id}`
                });

                this.delete_item_dialog_loading = false;

                // close dialog
                this.closeDeleteItemDialog();

                // successful response
                if (!response.response) {

                    // get items
                    await this.getData();
                }
            }
        }
    }
</script>