<template>
    <v-dialog v-model="show_dialog" :persistent="true" max-width="1264px" scrollable>
        <v-card class="custom_card">

            <v-card-title>
                <span class="headline">{{header}}</span>
            </v-card-title>

            <v-divider></v-divider>

            <v-card-text id="manage_client_page">
                <div v-if="loading_data" class="pb-4">
                    <v-container fluid class="client_loading"
                                 :class="[loading_data ? 'justify-center fill-height' : '']">
                        <v-progress-circular :size="50" indeterminate color="primary"></v-progress-circular>
                    </v-container>
                </div>
                <div v-else class="pb-4">

                    <!--General info-->
                    <v-row dense>
                        <v-col cols="12">
                            <div class="title mb-4">{{$t('clients.personal_data')}}</div>
                        </v-col>
                        <v-col cols="12" sm="6" md="4" lg="3">
                            <v-text-field name="first_name" type="text" class="custom_field"
                                          @keyup="$Helper.methods.uppercase"
                                          v-model="item.first_name"
                                          :label="$t('general.first_name')" outlined clearable
                                          :disabled="isFieldsDisabled" @input="$v.item.first_name.$touch()"
                                          @blur="$v.item.first_name.$touch()"
                                          :error-messages="fieldErrors('first_name')">
                            </v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="4" lg="3">
                            <v-text-field name="last_name" type="text" class="custom_field uppercase"
                                          v-model="item.last_name"
                                          @keyup="$Helper.methods.uppercase"
                                          :label="$t('general.last_name')" outlined clearable
                                          :disabled="isFieldsDisabled" @input="$v.item.last_name.$touch()"
                                          @blur="$v.item.last_name.$touch()"
                                          :error-messages="fieldErrors('last_name')">
                            </v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="4" lg="3">
                            <v-text-field name="phone_number" type="text" class="custom_field"
                                          v-model="item.phone_number" :label="$t('general.phone_number')" outlined
                                          clearable :disabled="isFieldsDisabled">
                            </v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="4" lg="3">
                            <v-text-field name="email" type="text" class="custom_field" v-model="item.email"
                                          @input="$v.item.email.$touch()" @blur="$v.item.email.$touch()"
                                          :error-messages="fieldErrors('email')"
                                          :label="$t('general.email')" outlined clearable :disabled="isFieldsDisabled">
                            </v-text-field>
                        </v-col>
                    </v-row>


                    <v-row dense>
                        <v-col cols="12">
                            <div class="title mb-4">{{$t('clients.notes')}}</div>
                        </v-col>
                        <v-col cols="12" sm="12" md="12" lg="12">
                            <v-textarea name="notes" class="custom_field uppercase" v-model="item.notes"
                                        @keyup="$Helper.methods.uppercase"
                                        :label="$t('clients.notes')" outlined clearable no-resize
                                        :disabled="isFieldsDisabled">
                            </v-textarea>
                        </v-col>
                    </v-row>
                </div>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions class="elevation-1">
                <v-spacer></v-spacer>
                <v-btn color="red" class="close_button" dark @click="closeDialog">
                    <v-icon color="white">close</v-icon>
                </v-btn>
                <v-btn v-if="action === 'view' && $Helper.methods.canUserDoAction(canUpdateItem, isSuperAdmin, item)"
                       class="update_button" dark @click="updateItem">
                    {{$t('general.update')}}
                </v-btn>
                <v-btn v-if="action !== 'view'" class="submit_button" dark :loading="is_loading"
                       @click="submitDialog()">
                    <v-icon color="white">save</v-icon>
                </v-btn>
            </v-card-actions>

        </v-card>
    </v-dialog>
</template>

<script>
    import {validationMixin} from 'vuelidate'
    import {required, email} from 'vuelidate/lib/validators'

    export default {
        props: {
            show: {type: Boolean, default: false},
            item_header: {type: String},
            item_action: {type: String},
            item_id: {type: Number}
        },
        components: {},
        mixins: [validationMixin],
        validations() {
            return {
                item: {
                    first_name: {required},
                    last_name: {required},
                    email: {email}
                }
            }
        },
        data: self => ({
            show_dialog: self.show,
            action: self.item_action,
            header: self.item_header,
            url: 'clients',
            loading_data: false,
            is_loading: false,
            offsetTop: 0,

            // actions permissions
            canUpdateItem: false,

            // item
            item: {
                first_name: null,
                last_name: null,
                phone_number: null,
                email: null,
                notes: null,
                created_at: null
            }
        }),
        computed: {
            isFieldsDisabled() {
                return this.action === 'view';
            },
            isSuperAdmin() {
                return this.$store.state.user_details.roles.map(obj => obj.name).indexOf('superadmin') > -1;
            }
        },
        watch: {
            async show(val) {
                this.show_dialog = val;

                if (val) {
                    this.action = this.item_action;

                    // update or view
                    if (['view', 'update'].indexOf(this.action) > -1) {

                        let response = await this.$store.dispatch('apiRequest', {
                            method: 'get', url: `${this.url}/${this.item_id}`,
                        });

                        // successful response
                        if (!response.response) {

                            // set item data
                            this.$nextTick().then(() => {
                                for (let prop in this.item) {
                                    this.item[prop] = response.data[prop];
                                }
                            });

                            // error response
                        } else {
                            this.clearDialog();
                            this.$emit('close-dialog');
                        }
                    }
                }
            },
            item_action(val) {
                this.action = val;
            },
            item_header(val) {
                this.header = val;
            },
        },
        methods: {
            // form field errors
            fieldErrors(prop) {
                const errors = [];
                if (!this.$v.item[prop].$dirty) return errors;

                // add email rules
                if (prop === 'email') {
                    !this.$v.item[prop].email && errors.push(this.$t('validation.email'));
                }

                // general rules
                if (['email'].indexOf(prop) === -1) {
                    !this.$v.item[prop].required && errors.push(this.$t('validation.required'));
                }

                return errors;
            },
            // clear dialog
            clearDialog() {
                this.$v.item.$reset();
                this.is_loading = false;

                for (let prop in this.item) {
                    this.item[prop] = null;
                }

                this.item_data = null;
            },
            // trigger close dialog event in parent component
            async closeDialog() {
                this.clearDialog();
                this.$emit('close-dialog');
            },
            // trigger submit dialog event in parent component
            async submitDialog() {
                this.$v.item.$touch();

                // item is invalid
                if (!this.$v.item.$invalid) {

                    this.is_loading = true;

                    let response = await this.$store.dispatch('apiRequest', {
                        method: 'post',
                        url: this.action !== 'create' ? `${this.url}/${this.item_id}` : this.url,
                        data: this.item
                    });

                    this.loading = false;

                    // successful response
                    if (!response.response) {
                        this.$emit('submit-dialog');
                        this.clearDialog();
                    }
                }
            },
            // change view to update
            updateItem() {
                this.action = 'update';
                this.header = this.$t('clients.update');
            }
        }
    }
</script>